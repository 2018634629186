import { COUNTRY_SHORTS } from '~/constants'
import { BookingStageEnum } from '~/lib/enums'
import type { BookingDetails, Nullable } from '~/types'

export const getBookingDetails = (booking?: Nullable<BookingDetails>) => {
  /**
   * Basic Booking properties.
   * Always in the format of `booking[PropertyName]`.
   * Defaults are dependent on the schema and added manually.
   */
  const bookingAcceptedOn = booking?.AcceptedOn ?? ''
  const bookingActiveGuestMoneyRequest = booking?.ActiveGuestMoneyRequest ?? null
  const bookingAdditionalBookingCharges = booking?.AdditionalBookingCharges ?? []
  const bookingAdults = booking?.Adults ?? 0
  const bookingAmountDue = booking?.AmountDue ?? 0
  const bookingAppliedOwnerCredit = booking?.AppliedOwnerCredit ?? 0
  const bookingAppliedRentalCredit = booking?.AppliedRentalCredit ?? 0
  const bookingAvatar = booking?.Avatar ?? undefined
  const bookingBookingChecklist = booking?.BookingChecklist ?? null
  const bookingBookingChecklistRenter = booking?.BookingChecklistRenter ?? null
  const bookingBookingDrivers = booking?.BookingDrivers ?? []
  const bookingBookingPreferences = booking?.BookingPreferences ?? []
  const bookingCancellationFee = booking?.CancellationFee ?? 0
  const bookingCancellationPolicy = booking?.CancellationPolicy ?? ''
  const bookingCancellationType = booking?.CancellationType
  const bookingCancelledOn = booking?.CancelledOn ?? ''
  const bookingCanGuestCreateReview = booking?.CanGuestCreateReview ?? false
  const bookingCanHostCreateReview = booking?.CanHostCreateReview ?? false
  const bookingCanRequestMoney = booking?.CanRequestMoney ?? false
  const bookingCanSendMoney = booking?.CanSendMoney ?? false
  const bookingChildren = booking?.Children ?? 0
  const bookingCountry = booking?.Country ? (COUNTRY_SHORTS[booking.Country as keyof typeof COUNTRY_SHORTS]) : undefined
  const bookingCustomTotalAddOnAmount = booking?.CustomTotalAddOnAmount ?? undefined
  const bookingCustomTotalRentalAmount = booking?.CustomTotalRentalAmount ?? undefined
  const bookingDateEnd = booking?.DateEnd ?? ''
  const bookingDateStart = booking?.DateStart ?? ''
  const bookingDaysInUS = booking?.DaysInUS ?? 0
  const bookingDeclineOrCancelReasonDetails = booking?.DeclineOrCancelReasonDetails
  const bookingDeliveryDistance = booking?.DeliveryDistance ?? 0
  const bookingDestination = booking?.Destination ?? ''
  const bookingDestinationLatitude = booking?.DestinationLatitude ?? 0
  const bookingDestinationLongitude = booking?.DestinationLongitude ?? 0
  const bookingDiscount = booking?.Discount ?? 0
  const bookingDiscountPercent = booking?.DiscountPercent ?? 0
  const bookingEndDate = booking?.EndDate ?? ''
  const bookingExpiredInstantBook = booking?.ExpiredInstantBook ?? false
  const bookingFirstPaymentDate = booking?.FirstPaymentDate ?? ''
  const bookingFullPaymentDate = booking?.FullPaymentDate ?? ''
  const bookingHasDelivery = booking?.HasDelivery ?? false
  const bookingHasDriver = booking?.HasDriver ?? false
  const bookingHasDriverApproved = booking?.HasDriverApproved ?? false
  const bookingHasPendingDispute = booking?.HasPendingDispute ?? false
  const bookingHasPendingInsuranceClaim = booking?.HasPendingInsuranceClaim ?? false
  const bookingHasRoadsideEvent = booking?.HasRoadsideEvent ?? false
  const bookingHasValidDriver = booking?.HasValidDriver ?? false
  const bookingId = booking?.Id ?? 0
  const bookingInsuranceClaim = booking?.InsuranceClaim ?? null
  const bookingInsurancePricingStrategy = booking?.InsurancePricingStrategy ?? ''
  const bookingIsDuplicate = booking?.IsDuplicate ?? false
  const bookingIsEZYmatch = booking?.IsEZYmatch ?? false
  const bookingIsFreshInstantBook = booking?.IsFreshInstantBook ?? false
  const bookingIsFreshInstantBooking = booking?.IsFreshInstantBooking ?? false
  const bookingIsIncomeProtected = booking?.IsIncomeProtected ?? null
  const bookingIsInstabook = booking?.IsInstabook ?? false
  const bookingIsInsured = booking?.IsInsured ?? false
  const bookingIsPaused = booking?.IsPaused ?? false
  const bookingIsShortStay = booking?.IsShortStay ?? false
  const bookingIsSpecialOffer = booking?.IsSpecialOffer ?? false
  const bookingLastFailedPaymentReason = booking?.LastFailedPaymentReason ?? ''
  const bookingModifiedByChangeRequestOrByAdminAdditionalCharge = booking?.ModifiedByChangeRequestOrByAdminAdditionalCharge ?? false
  const bookingNoPaymentMethod = booking?.NoPaymentMethod ?? false
  const bookingNumberOfDays = booking?.NumberOfDays ?? 0
  const bookingNumberOfNights = booking?.NumberOfNight ?? 0
  const bookingOwnerCanCreateClaim = booking?.OwnerCanCreateClaim ?? false
  const bookingOwnerCanSubmitInsuranceClaim = booking?.OwnerCanSubmitInsuranceClaim ?? false
  const bookingOwnerCloseoutOn = booking?.OwnerCloseoutOn ?? null
  const bookingOwnerId = booking?.OwnerId ?? 0
  const bookingOwnerServiceFeePct = booking?.OwnerServiceFeePct ?? 0
  const bookingPaymentFailed = booking?.PaymentFailed ?? false
  const bookingPaymentMethod = booking?.PaymentMethod ?? null
  const bookingPaymentSchedule = booking?.PaymentSchedule ?? null
  const bookingPets = Boolean(booking?.Pets)
  const bookingPreviousStage = booking?.PreviousStage ?? ''
  const bookingProtectionLevel = booking?.ProtectionLevel ?? null
  const bookingReferralCreditPayout = booking?.ReferralCreditPayout ?? 0
  const bookingReferralCreditRental = booking?.ReferralCreditRental ?? 0
  const bookingRentalContractIsSignedByOwner = booking?.RentalContractIsSignedByOwner ?? false
  const bookingRentalContractIsSignedByRenter = booking?.RentalContractIsSignedByRenter ?? false
  const bookingRenterCloseoutOn = booking?.RenterCloseoutOn ?? null
  const bookingRenterId = booking?.RenterId ?? 0
  const bookingRenterServiceFee = booking?.RenterServiceFee ?? 0
  const bookingRequestDate = booking?.RequestDate ?? ''
  const bookingRequestedPaymentMode = booking?.RequestedPaymentMode ?? ''
  const bookingResidenceTaxPct = booking?.ResidenceTaxPct ?? 0
  const bookingReviewCanBeMadeOn = booking?.ReviewCanBeMadeOn ?? ''
  const bookingReviewCanNoLongerBeMadeOn = booking?.ReviewCanNoLongerBeMadeOn ?? ''
  const bookingReviews = booking?.Reviews ?? []
  const bookingRoadsideAssistanceDailyFee = booking?.RoadsideAssistanceDailyFee ?? 0
  const bookingRoadsideAssistanceState = booking?.RoadsideAssistanceState ?? false
  const bookingRVId = booking?.RVId ?? ''
  const bookingRVName = booking?.RVName ?? ''
  const bookingRvUsage = booking?.RvUsage
  const bookingShortIntroduce = booking?.ShortIntroduce ?? ''
  const bookingStage = booking?.Stage
  const bookingStartDate = booking?.StartDate ?? ''
  const bookingState = booking?.Stage ? BookingStageEnum[booking.Stage] : 0
  const bookingStatus = booking?.Status ?? ''
  const bookingSubTotal = booking?.SubTotal ?? 0
  const bookingTaxRemittanceStatus = booking?.TaxRemittanceStatus ?? ''
  const bookingTripIsInProgress = booking?.TripIsInProgress ?? false
  const bookingType = booking?.Type
  const bookingHostRentalAgreementStatus = booking?.HostRentalAgreementStatus ?? ''
  const bookingGuestRentalAgreementStatus = booking?.GuestRentalAgreementStatus ?? ''

  // Advanced propeties.
  const bookingCompletedAdditionalBookingCharges = bookingAdditionalBookingCharges.filter((charge) => 'Completed' === charge.Status)
  const bookingCurrentAdditionalBookingCharges = bookingAdditionalBookingCharges.find((charge) => 'Cancelled' !== charge.Status && 'Completed' !== charge.Status)
  const bookingDisputedAdditionalBookingCharges = bookingAdditionalBookingCharges.filter((charge) => 'Disputed' === charge.Status)
  // TODO: rename this one and the three above..
  const bookingRequestMoneyRequested = bookingAdditionalBookingCharges.some((charge) => 'Pending' === charge.Status || 'FailedPayment' === charge.Status)
  const bookingDestinationCountry = bookingDestination.split(', ').pop()
  const bookingDestinationIsInCA = bookingDestination.endsWith('Canada')
  const bookingDestinationIsInUS = bookingDestination.endsWith('USA') || bookingDestination.endsWith('United States')
  const bookingDistancePriceKms = booking?.RVSnapshot?.IsChargeMileage ? (booking?.RVSnapshot?.SurchargePerExtraKM ?? undefined) : undefined
  const bookingOriginalRate = booking?.RVSnapshot?.OriginalDefaultPrice ?? 0
  const bookingSmartPricingRate = booking?.RVSnapshot?.HasSmartPricingApplied ? booking?.RVSnapshot?.DefaultPrice : undefined

  // Advanced `is` checks.
  const bookingIsCancelledWithPayoutGuarantee = Boolean(bookingIsIncomeProtected && bookingCancellationType === 'AdminCancellation')
  const bookingIsGoingToFestivalOrEvent = Boolean(booking?.RVSnapshot?.RentalType !== 'RVCottage' && booking?.BookingPreferences?.some((preference) => preference.PreferenceType === 'AllowFestivalEventsParticipation' && preference.RequiredType === 'Required'))
  const bookingIsDelivery = Boolean(booking?.RvUsage === 'Delivery' || booking?.RvUsage === 'StationaryAfterDelivery')
  const bookingIsInCanada = bookingCountry === 'CA'
  const bookingIsInUs = bookingCountry === 'US'
  const bookingIsPrepaid = bookingStage === 'AwaitingOwnerApproval' && !bookingNoPaymentMethod

  // Advanced `has` checks.
  const bookingHasCustomQuote = Number(booking?.CustomTotalRentalAmount) > 0
  const bookingHasDispute = bookingDisputedAdditionalBookingCharges.length > 0
  const bookingHasEnded = bookingStage ? ['Completed', 'CompleteInProcess', 'ProcessCompleteFail', 'PendingDispute', 'PendingInsuranceClaim'].includes(bookingStage) : false
  const bookingHasFomoTagsEnabled = !bookingIsEZYmatch && !bookingHasCustomQuote
  const bookingHasInsuranceClaim = Boolean(bookingInsuranceClaim)
  const bookingHasMoneyRequested = ['Pending', 'FailedPayment'].includes(bookingCurrentAdditionalBookingCharges?.Status ?? '')
  const bookingHasReviewPhotos = bookingReviews.some((review) => review?.Photos?.length)
  const bookingHasReviews = Boolean(bookingReviews.length)
  const bookingHasVerifiedDrivers = bookingBookingDrivers.some((driver) => driver.Status === 'Approved')
  const bookingHasExperiencedDriver = Boolean(booking?.BookingPreferences?.find((preference) => preference.PreferenceType === 'AllowOnlyExperiencedSimilarRV' && preference.RequiredType !== 'NotRequired')?.RenterAnswer?.toLowerCase() === 'true')

  // Booking preferences helpers.
  const bookingPreferenceAttendingFestivalOrEvent = bookingBookingPreferences.find((preference) => preference.PreferenceType === 'AllowFestivalEventsParticipation')
  const bookingPreferenceFestivalOrEventDestination = bookingPreferenceAttendingFestivalOrEvent?.RenterAnswerDetails
  const bookingPreferenceSimilarRvExperience = bookingBookingPreferences.find((preference) => preference.PreferenceType === 'AllowOnlyExperiencedSimilarRV')

  // Stage helpers
  const bookingStageCanBeAccepted = bookingStage === 'AwaitingOwnerApproval'

  const bookingStageCanBeDeclined = bookingStage === 'EnquiryRequest'
    || bookingStage === 'InstantBooking'
    || bookingStage === 'Invoice'
    || bookingStage === 'AwaitingOwnerApproval'
    || bookingStage === 'DepositTaken'
    || bookingStage === 'SecurityDepositTaken'
    || bookingStage === 'FullPaymentReceived'
    || bookingStage === 'AwaitingPayment'

  const bookingStageCanEditDates = bookingStage === 'AwaitingOwnerApproval'
    || bookingStage === 'AwaitingPayment'
    || bookingStage === 'DepositTaken'
    || bookingStage === 'SecurityDepositTaken'
    || bookingStage === 'FullPaymentReceived'
    || bookingStage === 'TripStarted'

  const bookingStageCanEditDetails = bookingStage === 'AwaitingOwnerApproval'
    || bookingStage === 'AwaitingPayment'
    || bookingStage === 'InstantBooking'
    || bookingStage === 'DepositTaken'
    || bookingStage === 'SecurityDepositTaken'
    || bookingStage === 'FullPaymentReceived'

  const bookingStageIsAwaitingOwnerApproval = bookingStage === 'AwaitingOwnerApproval'
  const bookingStageIsAwaitingPayment = bookingStage === 'AwaitingPayment'

  const bookingStageIsCanceled = bookingStage === 'CanceledByTheAdmin'
    || bookingStage === 'CanceledByTheOwner'
    || bookingStage === 'CanceledByTheRenter'

  const bookingStageIsConfirmed = bookingStage === 'CompleteInProcess'
    || bookingStage === 'DepositTaken'
    || bookingStage === 'FullPaymentReceived'
    || bookingStage === 'InstantBooking'
    || bookingStage === 'PendingInsuranceClaim'
    || bookingStage === 'PendingPayout'
    || bookingStage === 'PendingPayoutOnCancel'
    || bookingStage === 'ProcessCompleteFail'
    || bookingStage === 'SecurityDepositTaken'
    || bookingStage === 'TripStarted'

  const bookingStageIsDeclined = bookingStage === 'DeclinedByTheOwner'
    || bookingStage === 'DeclinedByTheRenter'

  const bookingStageIsExpired = bookingStage === 'ExpiredByAdmin'
    || bookingStage === 'ExpiredInstantBook'
    || bookingStage === 'ExpiredNoPayment'
    || bookingStage === 'ExpiredRequest'

  const bookingStageHasStarted = bookingStage === 'TripStarted'
    || bookingStage === 'Completed'
    || bookingStage === 'CompleteInProcess'
    || bookingStage === 'Invoice'
    || bookingStage === 'ProcessCompleteFail'
    || bookingStage === 'PendingInsuranceClaim'

  // TODO: write tests for these.
  const bookingFinancial = booking?.Financial ?? null
  const bookingPromotionCode = booking?.PromotionCode ?? ''
  const bookingPromotionId = booking?.PromotionId ?? null
  const bookingRVSnapshot = booking?.RVSnapshot ?? null

  // bookingCurrentAdditionalBookingCharges helpers.
  const bookingCurrentAdditionalBookingChargesTotal
    = (bookingCurrentAdditionalBookingCharges?.GeneratorAmount ?? 0)
      + (bookingCurrentAdditionalBookingCharges?.MileageAmount ?? 0)
      + (bookingCurrentAdditionalBookingCharges?.DamageAmount ?? 0)
      + (bookingCurrentAdditionalBookingCharges?.OtherAmount ?? 0)

  return {
    bookingAcceptedOn,
    bookingActiveGuestMoneyRequest,
    bookingAdditionalBookingCharges,
    bookingAdults,
    bookingAmountDue,
    bookingAppliedOwnerCredit,
    bookingAppliedRentalCredit,
    bookingAvatar,
    bookingBookingChecklist,
    bookingBookingChecklistRenter,
    bookingBookingDrivers,
    bookingBookingPreferences,
    bookingCancellationFee,
    bookingCancellationPolicy,
    bookingCancellationType,
    bookingCancelledOn,
    bookingCanGuestCreateReview,
    bookingCanHostCreateReview,
    bookingCanRequestMoney,
    bookingCanSendMoney,
    bookingChildren,
    bookingCountry,
    bookingCustomTotalAddOnAmount,
    bookingCustomTotalRentalAmount,
    bookingDateEnd,
    bookingDateStart,
    bookingDaysInUS,
    bookingDeclineOrCancelReasonDetails,
    bookingDeliveryDistance,
    bookingDestination,
    bookingDestinationLatitude,
    bookingDestinationLongitude,
    bookingDiscount,
    bookingDiscountPercent,
    bookingEndDate,
    bookingExpiredInstantBook,
    bookingFirstPaymentDate,
    bookingFullPaymentDate,
    bookingHasDelivery,
    bookingHasDriver,
    bookingHasDriverApproved,
    bookingHasPendingDispute,
    bookingHasPendingInsuranceClaim,
    bookingHasRoadsideEvent,
    bookingHasValidDriver,
    bookingId,
    bookingInsuranceClaim,
    bookingInsurancePricingStrategy,
    bookingIsDuplicate,
    bookingIsEZYmatch,
    bookingIsFreshInstantBook,
    bookingIsFreshInstantBooking,
    bookingIsIncomeProtected,
    bookingIsInstabook,
    bookingIsInsured,
    bookingIsPaused,
    bookingIsShortStay,
    bookingIsSpecialOffer,
    bookingLastFailedPaymentReason,
    bookingModifiedByChangeRequestOrByAdminAdditionalCharge,
    bookingNoPaymentMethod,
    bookingNumberOfDays,
    bookingNumberOfNights,
    bookingOwnerCanCreateClaim,
    bookingOwnerCanSubmitInsuranceClaim,
    bookingOwnerCloseoutOn,
    bookingOwnerId,
    bookingOwnerServiceFeePct,
    bookingPaymentFailed,
    bookingPaymentMethod,
    bookingPaymentSchedule,
    bookingPets,
    bookingPreviousStage,
    bookingProtectionLevel,
    bookingReferralCreditPayout,
    bookingReferralCreditRental,
    bookingRentalContractIsSignedByOwner,
    bookingRentalContractIsSignedByRenter,
    bookingRenterCloseoutOn,
    bookingRenterId,
    bookingRenterServiceFee,
    bookingRequestDate,
    bookingRequestedPaymentMode,
    bookingResidenceTaxPct,
    bookingReviewCanBeMadeOn,
    bookingReviewCanNoLongerBeMadeOn,
    bookingReviews,
    bookingRoadsideAssistanceDailyFee,
    bookingRoadsideAssistanceState,
    bookingRVId,
    bookingRVName,
    bookingRvUsage,
    bookingShortIntroduce,
    bookingStage,
    bookingStartDate,
    bookingState,
    bookingStatus,
    bookingSubTotal,
    bookingTaxRemittanceStatus,
    bookingTripIsInProgress,
    bookingType,
    bookingHostRentalAgreementStatus,
    bookingGuestRentalAgreementStatus,

    bookingCompletedAdditionalBookingCharges,
    bookingCurrentAdditionalBookingCharges,
    bookingCurrentAdditionalBookingChargesTotal,
    bookingDisputedAdditionalBookingCharges,
    bookingRequestMoneyRequested,
    bookingDestinationCountry,
    bookingDestinationIsInCA,
    bookingDestinationIsInUS,
    bookingDistancePriceKms,
    bookingOriginalRate,
    bookingSmartPricingRate,

    bookingIsCancelledWithPayoutGuarantee,
    bookingIsGoingToFestivalOrEvent,
    bookingIsDelivery,
    bookingIsInCanada,
    bookingIsInUs,
    bookingIsPrepaid,

    bookingHasCustomQuote,
    bookingHasDispute,
    bookingHasEnded,
    bookingHasFomoTagsEnabled,
    bookingHasInsuranceClaim,
    bookingHasMoneyRequested,
    bookingHasReviewPhotos,
    bookingHasReviews,
    bookingHasVerifiedDrivers,
    bookingHasExperiencedDriver,

    bookingPreferenceAttendingFestivalOrEvent,
    bookingPreferenceFestivalOrEventDestination,
    bookingPreferenceSimilarRvExperience,

    bookingStageCanBeAccepted,
    bookingStageCanBeDeclined,
    bookingStageCanEditDates,
    bookingStageCanEditDetails,
    bookingStageIsAwaitingOwnerApproval,
    bookingStageIsAwaitingPayment,
    bookingStageIsCanceled,
    bookingStageIsConfirmed,
    bookingStageIsDeclined,
    bookingStageIsExpired,
    bookingStageHasStarted,

    bookingFinancial,
    bookingPromotionCode,
    bookingPromotionId,
    bookingRVSnapshot,
  }
}
